/* You can add global styles to this file, and also import other style files */
.custom-alert{
    position: fixed;
    width:100%;
    top:0px;
    right:0;
    z-index: 1;
}
agm-map {
  height: 240px;
}
tr {
  cursor: pointer;
}

.nsm-dialog-btn-close{  
  top:20px;
}
  
.custom-checkbox-switch label:before{
  margin-right:0.32rem;
}

.nsm-content{
  padding: .8rem;
  height: 60%;
}
.nsm-body{
  height: 70%;
}
.nsm-dialog{
  max-width: 728px;
}